import type { ChangeEvent, Key } from "react";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { omit } from "lodash";
import { useWatch } from "react-hook-form";
import { useValidationQuestionProvider } from "./context/CreateValidatedQuestionContext";
import { Box, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Checkbox from "@mui/material/Checkbox";
import { FormGroup, FormControlLabel, Button } from "@mui/material";

import { useGetCredentialDetailsForIndicatorsQuery } from "src/graphql/generated/api/graphql";
import { uniquePropFrom } from "src/lib/uniquePropFrom/uniquePropFrom";
import InfoBlock from "src/components/atoms/InfoBlock/InfoBlock";
import Parameters from "./steps/Parameters/Parameters";
import IndicatorPopup from "@/src/components/organisms/IndicatorPopup/IndicatorPopup.component";

function InfoBox() {
    const validatedQuestions = useValidationQuestionProvider(),
        { confirmed, setConfirmed } = validatedQuestions;

    const [expand, setExpand] = useState<Key[]>([]);

    function isConfirmed(key: string) {
        return confirmed.includes(key);
    }

    function isExpanded(key: Key) {
        return expand.includes(key);
    }

    const handleConfirm =
        (key: string) =>
        (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            event.stopPropagation();

            setConfirmed((prev) =>
                checked ? [...prev, key] : prev.filter((i) => i !== key)
            );

            if (isExpanded(key) && checked) {
                setExpand((prev) => prev.filter((i) => i !== key));
            }
        };

    const handleExpand = (key: Key) => (_: any, checked: boolean) => {
        setExpand((prev) =>
            checked ? [...prev, key] : prev.filter((i) => i !== key)
        );
    };

    const { profile } = validatedQuestions;

    const version = useWatch({
        control: validatedQuestions.form.control,
        name: "version",
    });

    const indicators = useWatch({
        control: validatedQuestions.form.control,
        name: "indicators",
    });

    const { t } = useTranslation();

    const { data, loading } = useGetCredentialDetailsForIndicatorsQuery({
        variables: {
            // @ts-expect-error `skip` ensures that these values are not undefined
            repositoryUrl: profile?.url,
            // @ts-expect-error TODO: ensure that `indicators` have a type (form)
            indicators: (indicators ?? []).map((indicator) =>
                omit(indicator, ["__typename", "parameters"])
            ),
            branchOrTag: version,
        },
        skip: !profile?.url || !indicators?.length || !version,
    });

    const { getCredentialDetailsForIndicators: detailsForIndicators } =
        data || {};

    //set the confirmed and expand state based on the response from the server
    useEffect(() => {
        if (!detailsForIndicators) return;

        detailsForIndicators.forEach((indicatorDetail) => {
            if (indicatorDetail.parameters?.length === 0)
                return void setConfirmed((prev) => [
                    ...prev,
                    indicatorDetail.queryName,
                ]);

            setExpand((prev) => [...prev, indicatorDetail.queryName]);
        });

        // merge the parameters from the details with the selected indicators
        indicators?.forEach((indicator) => {
            const indicatorDetail = detailsForIndicators.find(
                (detail) => detail.queryName === indicator.name
            );

            if (!indicatorDetail) return;

            const updatedIndicator = {
                ...indicator,
                parameters: indicatorDetail.parameters.map((parameter) =>
                    omit(parameter, ["possibleValues"])
                ),
            };

            const index = indicators.findIndex(
                (i) => i.name === indicator.name
            );

            validatedQuestions.form.setValue(
                `indicators.${index}`,
                updatedIndicator
            );
        });
    }, [detailsForIndicators]);

    const [opened, setOpened] = useState<Key | null>(null),
        isOpened = (key: Key) => opened === key,
        handleDetailOpen = (key: Key) => setOpened(key),
        handleDetailClose = () => setOpened(null);

    if (!detailsForIndicators?.length) return null;

    if (loading) return <CircularProgress />;

    return (
        <div>
            <Stack spacing={2}>
                <Typography>{t("info-box.instruction")}</Typography>
                {profile?.name && (
                    <InfoBlock
                        label={t("info-box.exchange-profile")}
                        value={profile.name}
                    />
                )}

                {indicators && (
                    <InfoBlock
                        testId="issue-at-date"
                        label={t("info-box.date")}
                        value={
                            uniquePropFrom(
                                indicators,
                                ({ issueAt }) => issueAt
                            ).map((issueAtDate = "") =>
                                issueAtDate.replace("T", " ")
                            )[0] || t("info-box.no-date")
                        }
                    />
                )}

                {version && (
                    <InfoBlock label={t("info-box.version")} value={version} />
                )}

                {detailsForIndicators.length ? (
                    <Typography>
                        <strong>{t("info-box.indicators")}:</strong>
                    </Typography>
                ) : null}

                {detailsForIndicators?.map((indicatorDetail, index) => (
                    <Fragment key={index}>
                        <Accordion
                            onChange={handleExpand(indicatorDetail.queryName)}
                            expanded={isExpanded(indicatorDetail.queryName)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width={"100%"}
                                >
                                    <Typography>
                                        {indicatorDetail.indicator?.name}
                                    </Typography>
                                    <FormGroup
                                        onClick={(e) => e.stopPropagation()}
                                        sx={{ paddingLeft: 2 }}
                                    >
                                        <FormControlLabel
                                            sx={{ marginRight: 1 }}
                                            control={
                                                <Checkbox
                                                    checked={isConfirmed(
                                                        indicatorDetail.queryName
                                                    )}
                                                    onChange={handleConfirm(
                                                        indicatorDetail.queryName
                                                    )}
                                                    size="small"
                                                />
                                            }
                                            label={
                                                <Typography variant="body2">
                                                    {t("info-box.confirm")}
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                {indicatorDetail?.parameters?.length ? (
                                    <div>
                                        <Typography>
                                            <strong>
                                                {t("info-box.parameters")}
                                            </strong>
                                        </Typography>
                                        <Parameters
                                            indicatorDetails={{
                                                queryName:
                                                    indicatorDetail.queryName,
                                                // @ts-expect-error TODO: make a mapper for this
                                                parameters:
                                                    indicatorDetail.parameters,
                                            }}
                                        />
                                    </div>
                                ) : null}
                            </AccordionDetails>

                            <AccordionActions>
                                <Button
                                    startIcon={<OpenInFullIcon />}
                                    variant="contained"
                                    onClick={() =>
                                        handleDetailOpen(
                                            indicatorDetail.queryName
                                        )
                                    }
                                >
                                    {t("info-box.details")}
                                </Button>
                            </AccordionActions>
                        </Accordion>

                        <IndicatorPopup
                            indicator={indicatorDetail}
                            name={indicatorDetail.indicator?.name || ""}
                            open={isOpened(indicatorDetail.queryName)}
                            onClose={handleDetailClose}
                        />
                    </Fragment>
                ))}
                <div />
            </Stack>
        </div>
    );
}

export default InfoBox;
