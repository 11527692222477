import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { range, toString } from "lodash";
import type { SelectChangeEvent } from "@mui/material/Select";
import {
    fromMultiSelectOption,
    MultiSelect,
    type MultiSelectOption,
    toMultiSelectOption,
} from "@/src/components/organisms/MultiSelect";

type Year = string;
type Years = Year[];

export interface YearParameterComponentProps {
    minInclusive: Year;
    maxInclusive: Year;
    value?: Years;
    onChange?: (years: Years) => void;
    label?: string;
}

export function YearParameter(props: YearParameterComponentProps) {
    const { t } = useTranslation("common"),
        label = props.label ?? t("year", "Jaartal");

    const years = useMemo(() => {
        const minInclusive = parseInt(props.minInclusive),
            maxInclusive = parseInt(props.maxInclusive) + 1,
            listOfYears = range(minInclusive, maxInclusive).map(toString);

        return toMultiSelectOption(listOfYears);
    }, [props.minInclusive, props.maxInclusive]);

    const value = useMemo(
        () => toMultiSelectOption(props.value ?? []),
        [props.value]
    );

    function handleChange(event: SelectChangeEvent<MultiSelectOption[]>) {
        if (Array.isArray(event.target.value))
            props.onChange?.(fromMultiSelectOption(event.target.value));
    }

    return (
        <MultiSelect
            label={label}
            options={years}
            value={value}
            onChange={handleChange}
        />
    );
}
