const dutchFormat = {
    name: "nl",
    months: [
        ["januari", "jan"],
        ["februari", "feb"],
        ["maart", "mrt"],
        ["april", "apr"],
        ["mei", "mei"],
        ["juni", "jun"],
        ["juli", "jul"],
        ["augustus", "aug"],
        ["september", "sep"],
        ["oktober", "okt"],
        ["november", "nov"],
        ["december", "dec"],
    ],
    weekDays: [
        ["zaterdag", "Z"],
        ["zondag", "Z"],
        ["maandag", "M"],
        ["dinsdag", "D"],
        ["woensdag", "W"],
        ["wonderdag", "D"],
        ["vrijdag", "V"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
        ["AM", "am"],
        ["PM", "pm"],
    ],
};

export default dutchFormat;
