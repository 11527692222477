import type { Key } from "react";
import { useState } from "react";
import type { RevokeCredentialsMutation } from "src/graphql/generated/api/graphql";
import {
    useGetIssuedCredentialsBySubjectDidForOverviewQuery,
    useRevokeCredentialsMutation,
} from "src/graphql/generated/api/graphql";
import Template from "../templates/ValidatedQuestionsBySubjectDidOverview/ValidatedQuestionsBySubjectDidOverview";
import mapValidatedQuestionsBySubjectDidByExchangeProfile from "@/src/lib/mapValidatedQuestionsBySubjectDidByExchangeProfile/mapValidatedQuestionsBySubjectDidByExchangeProfile";
import castNumber from "@/src/lib/castNumber/castNumber";

const ValidatedQuestionsBySubjectDidOverview = () => {
    const [revokeResults, setRevokeResults] =
        useState<RevokeCredentialsMutation["revokeCredentials"]>();

    const [openRevokeResultsDialog, setOpenRevokeResultsDialog] =
        useState(false);

    const [mutate] = useRevokeCredentialsMutation();

    const [filter, setFilter] = useState<Key[]>([]);

    const {
        loading,
        data: exchangeProfiles,
        refetch,
    } = useExchangeProfiles(filter.map(castNumber));

    const groupBySubjectDid =
        mapValidatedQuestionsBySubjectDidByExchangeProfile(exchangeProfiles);

    const handleRevoke = (indicators: number[]) => {
        mutate({
            variables: {
                ids: indicators,
            },
        }).then((data) => {
            if (
                data.data?.revokeCredentials &&
                data.data?.revokeCredentials.length
            ) {
                setOpenRevokeResultsDialog(true);
                setRevokeResults(data.data?.revokeCredentials);
                refetch();
            }
        });
    };

    return (
        <Template
            loading={loading}
            subjectDids={groupBySubjectDid}
            indicators={
                exchangeProfiles?.getIssuedVerifiableQueryCredentials ?? []
            }
            onRevoke={(indicators) => {
                const indicatorIds = indicators.map(
                    (indicator) => indicator.id,
                    []
                );
                handleRevoke(indicatorIds);
            }}
            onFilter={setFilter}
            filter={filter}
            revokeResults={revokeResults}
            openRevokeResults={openRevokeResultsDialog}
            onCloseRevokeResults={() => setOpenRevokeResultsDialog(false)}
        />
    );
};

function useExchangeProfiles(subjectIds: number[] = []) {
    const { loading, data, refetch } =
        useGetIssuedCredentialsBySubjectDidForOverviewQuery({
            variables: {
                filters: {
                    subjectIds,
                },
            },
        });

    return {
        loading,
        refetch,
        data,
    };
}

export default ValidatedQuestionsBySubjectDidOverview;
