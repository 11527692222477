import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  LocalDateTime: { input: any; output: any; }
  Long: { input: any; output: any; }
  ParameterInput: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type ActionResult = {
  __typename?: 'ActionResult';
  success: Scalars['Boolean']['output'];
};

export type BaseParameter = {
  path: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CreateSubjectDidInput = {
  name: Scalars['String']['input'];
  zinlDid: Scalars['String']['input'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  temporaryPassword: Scalars['String']['input'];
};

export type CredentialMutationFailureDto = {
  __typename?: 'CredentialMutationFailureDto';
  location: FailureLocation;
  reasonPhrase: Scalars['String']['output'];
};

export type CredentialRevocationResultDto = {
  __typename?: 'CredentialRevocationResultDto';
  id: Scalars['UUID']['output'];
  successful: Scalars['Boolean']['output'];
  unsuccessful?: Maybe<CredentialMutationFailureDto>;
};

export type ExchangeProfileDto = {
  __typename?: 'ExchangeProfileDto';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tagsAndBranches: Array<TagOrBranch>;
  url: Scalars['String']['output'];
};

export enum FailureLocation {
  Clientside = 'CLIENTSIDE',
  Serverside = 'SERVERSIDE'
}

export type FilterIssuedHealthcareProviderExcerptCredentialInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GitRemoteInput = {
  branchOrTag?: InputMaybe<Scalars['String']['input']>;
  repositoryUrl: Scalars['String']['input'];
};

export type HealthcareProviderDto = {
  __typename?: 'HealthcareProviderDto';
  cocNumber: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
};

export type HealthcareProviderExcerptCredentialIssuanceResultDto = {
  __typename?: 'HealthcareProviderExcerptCredentialIssuanceResultDto';
  id: Scalars['Long']['output'];
  successful: Scalars['Boolean']['output'];
  unsuccessful?: Maybe<CredentialMutationFailureDto>;
};

export type IndicatorDetailDto = {
  __typename?: 'IndicatorDetailDto';
  indicator: IndicatorDto;
  ontologies: Array<OntologyDto>;
  parameters: Array<ParameterDto>;
  profileUrl: Scalars['String']['output'];
  queryDescription: Scalars['String']['output'];
  queryName: Scalars['String']['output'];
  sparql: Scalars['String']['output'];
};

export type IndicatorDto = {
  __typename?: 'IndicatorDto';
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type IndicatorInput = {
  issueAt?: InputMaybe<Scalars['LocalDateTime']['input']>;
  name: Scalars['String']['input'];
  parameters?: InputMaybe<Array<Scalars['ParameterInput']['input']>>;
  path: Scalars['String']['input'];
};

export enum IssuedCredentialStatusEnum {
  Issued = 'ISSUED',
  NotIssued = 'NOT_ISSUED'
}

export type IssuedHealthcareProviderExcerptCredentialDto = {
  __typename?: 'IssuedHealthcareProviderExcerptCredentialDto';
  healthcareProvider: HealthcareProviderDto;
  id: Scalars['UUID']['output'];
  revokedBy?: Maybe<Scalars['String']['output']>;
};

export type IssuedHealthcareProviderExcerptCredentialPage = {
  __typename?: 'IssuedHealthcareProviderExcerptCredentialPage';
  page: Scalars['Int']['output'];
  rowCount: Scalars['Int']['output'];
  rows: Array<IssuedHealthcareProviderExcerptCredentialDto>;
};

export type IssuedVerifiableQueryCredentialDto = {
  __typename?: 'IssuedVerifiableQueryCredentialDto';
  exchangeProfile: Scalars['String']['output'];
  exchangeProfileName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  indicatorName: Scalars['String']['output'];
  indicatorPath: Scalars['String']['output'];
  issueAt?: Maybe<Scalars['LocalDateTime']['output']>;
  revokedBy?: Maybe<Scalars['String']['output']>;
  status: IssuedCredentialStatusEnum;
  subjectDid: SubjectDidDto;
  tagOrBranch?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createSubjectDid: SubjectDidDto;
  /** Create a new user */
  createUser: UserDto;
  /** Deactivate a user */
  deactivateUser: UserDto;
  /** Issue a Healthcare provider excerpt-credential for each of the given IDs */
  issueCredentialsForHealthcareProviders: Array<HealthcareProviderExcerptCredentialIssuanceResultDto>;
  /** For each of the given indicators within the given repo, issue a Verifiable Query Credential for the given subjectId and return whether that was successful */
  issueCredentialsForIndicators: Array<VerifiableQueryCredentialIssuanceResultDto>;
  /**
   * Revoke one or more credentials
   * @deprecated Use 'revokeVerifiableQueryCredentials(ids) instead, replace with revokeVerifiableQueryCredentials(ids)
   */
  revokeCredentials: Array<CredentialRevocationResultDto>;
  /** Revoke one or more Healthcare Provider Excerpt credentials */
  revokeHealthcareProviderExcerptCredential: Array<CredentialRevocationResultDto>;
  /** Revoke a subject with a given ID */
  revokeSubjectDid: ActionResult;
  /** Revoke one or more Verifiable Query credentials */
  revokeVerifiableQueryCredentials: Array<CredentialRevocationResultDto>;
  updateSubjectDid: SubjectDidDto;
};


export type MutationCreateSubjectDidArgs = {
  input: CreateSubjectDidInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeactivateUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationIssueCredentialsForHealthcareProvidersArgs = {
  ids: Array<Scalars['Long']['input']>;
};


export type MutationIssueCredentialsForIndicatorsArgs = {
  indicators: Array<IndicatorInput>;
  input: GitRemoteInput;
  subjectIds: Array<Scalars['Long']['input']>;
};


export type MutationRevokeCredentialsArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type MutationRevokeHealthcareProviderExcerptCredentialArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type MutationRevokeSubjectDidArgs = {
  id: Scalars['Long']['input'];
};


export type MutationRevokeVerifiableQueryCredentialsArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type MutationUpdateSubjectDidArgs = {
  input: UpdateSubjectDidInput;
};

export type OntologyDto = {
  __typename?: 'OntologyDto';
  tagOrBranch: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PaginationInput = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type ParameterDto = {
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type QuarterParameterDto = BaseParameter & ParameterDto & {
  __typename?: 'QuarterParameterDto';
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  possibleValues: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type QuarterParameterInput = {
  path: Scalars['String']['input'];
  possibleValues: Array<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** Get the details for each of the given indicators */
  getCredentialDetailsForIndicators: Array<IndicatorDetailDto>;
  /** Get list of exchange profiles */
  getExchangeProfiles: Array<ExchangeProfileDto>;
  /** Get list of healthcare providers */
  getHealthcareProviders: Array<HealthcareProviderDto>;
  /**
   * Get list of issued Verifiable Query credentials
   * @deprecated Use 'getIssuedVerifiableQueryCredentials' instead, replace with getIssuedVerifiableQueryCredentials()
   */
  getIssuedCredentials: Array<IssuedVerifiableQueryCredentialDto>;
  /** Get a page of issued healthcare provider excerpt credentials */
  getIssuedHealthcareProviderExcerptCredentials: IssuedHealthcareProviderExcerptCredentialPage;
  /** Get list of issued Verifiable Query credentials */
  getIssuedVerifiableQueryCredentials: Array<IssuedVerifiableQueryCredentialDto>;
  /** Get a subject did by id */
  getSubjectDid: SubjectDidDto;
  /** Get list of subject dids */
  getSubjectDids: Array<SubjectDidDto>;
  /** Get list of users */
  getUsers: Array<UserDto>;
  /** Very simple ping-pong call to test basic GraphQL functionality */
  ping: Scalars['String']['output'];
};


export type QueryGetCredentialDetailsForIndicatorsArgs = {
  indicators: Array<IndicatorInput>;
  input: GitRemoteInput;
};


export type QueryGetIssuedHealthcareProviderExcerptCredentialsArgs = {
  filter?: InputMaybe<FilterIssuedHealthcareProviderExcerptCredentialInput>;
  pagination: PaginationInput;
};


export type QueryGetIssuedVerifiableQueryCredentialsArgs = {
  filters: VerifiableCredentialsFiltersInput;
};


export type QueryGetSubjectDidArgs = {
  id: Scalars['Long']['input'];
};

export type ReferenceDateParameterDto = BaseParameter & ParameterDto & {
  __typename?: 'ReferenceDateParameterDto';
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ReferenceDateParameterInput = {
  path: Scalars['String']['input'];
  possibleValues: Array<Scalars['Date']['input']>;
  type: Scalars['String']['input'];
};

export type SubjectDidDto = {
  __typename?: 'SubjectDidDto';
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  zinlDid: Scalars['String']['output'];
};

export type TagOrBranch = {
  __typename?: 'TagOrBranch';
  tagOrBranch: Scalars['String']['output'];
};

export type UpdateSubjectDidInput = {
  id: Scalars['Long']['input'];
  name: Scalars['String']['input'];
};

export type UserDto = {
  __typename?: 'UserDto';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
};

export type VerifiableCredentialsFiltersInput = {
  subjectIds: Array<Scalars['Long']['input']>;
};

export type VerifiableQueryCredentialIssuanceResultDto = {
  __typename?: 'VerifiableQueryCredentialIssuanceResultDto';
  indicator: IndicatorDto;
  subjectDid: Scalars['String']['output'];
  successful: Scalars['Boolean']['output'];
  unsuccessful?: Maybe<CredentialMutationFailureDto>;
};

export type YearParameterDto = BaseParameter & ParameterDto & {
  __typename?: 'YearParameterDto';
  maxValue: Scalars['String']['output'];
  minValue: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type YearParameterInput = {
  path: Scalars['String']['input'];
  possibleValues: Array<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type GetHealthcareProvidersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHealthcareProvidersQuery = { __typename?: 'Query', getHealthcareProviders: Array<{ __typename?: 'HealthcareProviderDto', id: any, name: string, cocNumber: string }> };

export type GetIssuedCredentialsQueryVariables = Exact<{
  pagination: PaginationInput;
  search: Scalars['String']['input'];
}>;


export type GetIssuedCredentialsQuery = { __typename?: 'Query', getIssuedHealthcareProviderExcerptCredentials: { __typename?: 'IssuedHealthcareProviderExcerptCredentialPage', page: number, rowCount: number, rows: Array<{ __typename?: 'IssuedHealthcareProviderExcerptCredentialDto', id: any, healthcareProvider: { __typename?: 'HealthcareProviderDto', id: any, name: string, cocNumber: string } }> } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: Array<{ __typename?: 'UserDto', id: string, email: string, firstName: string, lastName: string, isEnabled: boolean }> };

export type GetIssuedCredentialsBySubjectDidForOverviewQueryVariables = Exact<{
  filters: VerifiableCredentialsFiltersInput;
}>;


export type GetIssuedCredentialsBySubjectDidForOverviewQuery = { __typename?: 'Query', getIssuedVerifiableQueryCredentials: Array<{ __typename?: 'IssuedVerifiableQueryCredentialDto', id: any, exchangeProfileName: string, indicatorName: string, status: IssuedCredentialStatusEnum, tagOrBranch?: string | null, subjectDid: { __typename?: 'SubjectDidDto', id: any, name: string } }> };

export type GetSubjectDidsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubjectDidsQuery = { __typename?: 'Query', getSubjectDids: Array<{ __typename?: 'SubjectDidDto', id: any, name: string, zinlDid: string }> };

export type GetExchangeProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExchangeProfilesQuery = { __typename?: 'Query', getExchangeProfiles: Array<{ __typename?: 'ExchangeProfileDto', id: string, name: string, url: string, tagsAndBranches: Array<{ __typename?: 'TagOrBranch', tagOrBranch: string }> }> };

export type GetCredentialDetailsForIndicatorsQueryVariables = Exact<{
  repositoryUrl: Scalars['String']['input'];
  branchOrTag?: InputMaybe<Scalars['String']['input']>;
  indicators: Array<IndicatorInput> | IndicatorInput;
}>;


export type GetCredentialDetailsForIndicatorsQuery = { __typename?: 'Query', getCredentialDetailsForIndicators: Array<{ __typename?: 'IndicatorDetailDto', queryName: string, queryDescription: string, sparql: string, profileUrl: string, indicator: { __typename?: 'IndicatorDto', name: string, path: string }, ontologies: Array<{ __typename?: 'OntologyDto', title: string, tagOrBranch: string }>, parameters: Array<{ __typename?: 'QuarterParameterDto', type: string, path: string, name: string, possibleValues: Array<string> } | { __typename?: 'ReferenceDateParameterDto', type: string, path: string, name: string } | { __typename?: 'YearParameterDto', type: string, path: string, name: string, maxValue: string, minValue: string }> }> };

export type GetSubjectDidQueryVariables = Exact<{
  id: Scalars['Long']['input'];
}>;


export type GetSubjectDidQuery = { __typename?: 'Query', getSubjectDid: { __typename?: 'SubjectDidDto', id: any, name: string, zinlDid: string } };

export type IssueCredentialsForHealthcareProvidersMutationVariables = Exact<{
  ids: Array<Scalars['Long']['input']> | Scalars['Long']['input'];
}>;


export type IssueCredentialsForHealthcareProvidersMutation = { __typename?: 'Mutation', issueCredentialsForHealthcareProviders: Array<{ __typename?: 'HealthcareProviderExcerptCredentialIssuanceResultDto', id: any, successful: boolean, unsuccessful?: { __typename?: 'CredentialMutationFailureDto', reasonPhrase: string, location: FailureLocation } | null }> };

export type RevokeHealthcareProviderExcerptCredentialMutationVariables = Exact<{
  ids: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type RevokeHealthcareProviderExcerptCredentialMutation = { __typename?: 'Mutation', revokeHealthcareProviderExcerptCredential: Array<{ __typename?: 'CredentialRevocationResultDto', id: any, successful: boolean, unsuccessful?: { __typename?: 'CredentialMutationFailureDto', reasonPhrase: string, location: FailureLocation } | null }> };

export type IssueCredentialsForIndicatorsMutationVariables = Exact<{
  repositoryUrl: Scalars['String']['input'];
  branchOrTag?: InputMaybe<Scalars['String']['input']>;
  indicators: Array<IndicatorInput> | IndicatorInput;
  subjectIds: Array<Scalars['Long']['input']> | Scalars['Long']['input'];
}>;


export type IssueCredentialsForIndicatorsMutation = { __typename?: 'Mutation', issueCredentialsForIndicators: Array<{ __typename?: 'VerifiableQueryCredentialIssuanceResultDto', successful: boolean, indicator: { __typename?: 'IndicatorDto', name: string, path: string }, unsuccessful?: { __typename?: 'CredentialMutationFailureDto', reasonPhrase: string, location: FailureLocation } | null }> };

export type RevokeCredentialsMutationVariables = Exact<{
  ids: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type RevokeCredentialsMutation = { __typename?: 'Mutation', revokeCredentials: Array<{ __typename?: 'CredentialRevocationResultDto', id: any, successful: boolean, unsuccessful?: { __typename?: 'CredentialMutationFailureDto', reasonPhrase: string, location: FailureLocation } | null }> };

export type CreateSubjectDidMutationVariables = Exact<{
  input: CreateSubjectDidInput;
}>;


export type CreateSubjectDidMutation = { __typename?: 'Mutation', createSubjectDid: { __typename?: 'SubjectDidDto', name: string, zinlDid: string, id: any } };

export type RevokeSubjectDidMutationVariables = Exact<{
  id: Scalars['Long']['input'];
}>;


export type RevokeSubjectDidMutation = { __typename?: 'Mutation', revokeSubjectDid: { __typename?: 'ActionResult', success: boolean } };

export type UpdateSubjectDidMutationVariables = Exact<{
  input: UpdateSubjectDidInput;
}>;


export type UpdateSubjectDidMutation = { __typename?: 'Mutation', updateSubjectDid: { __typename?: 'SubjectDidDto', name: string, zinlDid: string } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserDto', firstName: string, lastName: string, id: string, email: string } };

export type DeactivateUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeactivateUserMutation = { __typename?: 'Mutation', deactivateUser: { __typename?: 'UserDto', firstName: string, id: string, isEnabled: boolean } };

export type PingQueryVariables = Exact<{ [key: string]: never; }>;


export type PingQuery = { __typename?: 'Query', ping: string };


export const GetHealthcareProvidersDocument = gql`
    query GetHealthcareProviders {
  getHealthcareProviders {
    id
    name
    cocNumber
  }
}
    `;

/**
 * __useGetHealthcareProvidersQuery__
 *
 * To run a query within a React component, call `useGetHealthcareProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthcareProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthcareProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHealthcareProvidersQuery(baseOptions?: Apollo.QueryHookOptions<GetHealthcareProvidersQuery, GetHealthcareProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHealthcareProvidersQuery, GetHealthcareProvidersQueryVariables>(GetHealthcareProvidersDocument, options);
      }
export function useGetHealthcareProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHealthcareProvidersQuery, GetHealthcareProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHealthcareProvidersQuery, GetHealthcareProvidersQueryVariables>(GetHealthcareProvidersDocument, options);
        }
export function useGetHealthcareProvidersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetHealthcareProvidersQuery, GetHealthcareProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHealthcareProvidersQuery, GetHealthcareProvidersQueryVariables>(GetHealthcareProvidersDocument, options);
        }
export type GetHealthcareProvidersQueryHookResult = ReturnType<typeof useGetHealthcareProvidersQuery>;
export type GetHealthcareProvidersLazyQueryHookResult = ReturnType<typeof useGetHealthcareProvidersLazyQuery>;
export type GetHealthcareProvidersSuspenseQueryHookResult = ReturnType<typeof useGetHealthcareProvidersSuspenseQuery>;
export type GetHealthcareProvidersQueryResult = Apollo.QueryResult<GetHealthcareProvidersQuery, GetHealthcareProvidersQueryVariables>;
export const GetIssuedCredentialsDocument = gql`
    query GetIssuedCredentials($pagination: PaginationInput!, $search: String!) {
  getIssuedHealthcareProviderExcerptCredentials(
    pagination: $pagination
    filter: {search: $search}
  ) {
    page
    rowCount
    rows {
      id
      healthcareProvider {
        id
        name
        cocNumber
      }
    }
  }
}
    `;

/**
 * __useGetIssuedCredentialsQuery__
 *
 * To run a query within a React component, call `useGetIssuedCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuedCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuedCredentialsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetIssuedCredentialsQuery(baseOptions: Apollo.QueryHookOptions<GetIssuedCredentialsQuery, GetIssuedCredentialsQueryVariables> & ({ variables: GetIssuedCredentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssuedCredentialsQuery, GetIssuedCredentialsQueryVariables>(GetIssuedCredentialsDocument, options);
      }
export function useGetIssuedCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssuedCredentialsQuery, GetIssuedCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssuedCredentialsQuery, GetIssuedCredentialsQueryVariables>(GetIssuedCredentialsDocument, options);
        }
export function useGetIssuedCredentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIssuedCredentialsQuery, GetIssuedCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIssuedCredentialsQuery, GetIssuedCredentialsQueryVariables>(GetIssuedCredentialsDocument, options);
        }
export type GetIssuedCredentialsQueryHookResult = ReturnType<typeof useGetIssuedCredentialsQuery>;
export type GetIssuedCredentialsLazyQueryHookResult = ReturnType<typeof useGetIssuedCredentialsLazyQuery>;
export type GetIssuedCredentialsSuspenseQueryHookResult = ReturnType<typeof useGetIssuedCredentialsSuspenseQuery>;
export type GetIssuedCredentialsQueryResult = Apollo.QueryResult<GetIssuedCredentialsQuery, GetIssuedCredentialsQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  getUsers {
    id
    email
    firstName
    lastName
    isEnabled
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetIssuedCredentialsBySubjectDidForOverviewDocument = gql`
    query GetIssuedCredentialsBySubjectDidForOverview($filters: VerifiableCredentialsFiltersInput!) {
  getIssuedVerifiableQueryCredentials(filters: $filters) {
    id
    exchangeProfileName
    indicatorName
    status
    tagOrBranch
    subjectDid {
      id
      name
    }
  }
}
    `;

/**
 * __useGetIssuedCredentialsBySubjectDidForOverviewQuery__
 *
 * To run a query within a React component, call `useGetIssuedCredentialsBySubjectDidForOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuedCredentialsBySubjectDidForOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuedCredentialsBySubjectDidForOverviewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetIssuedCredentialsBySubjectDidForOverviewQuery(baseOptions: Apollo.QueryHookOptions<GetIssuedCredentialsBySubjectDidForOverviewQuery, GetIssuedCredentialsBySubjectDidForOverviewQueryVariables> & ({ variables: GetIssuedCredentialsBySubjectDidForOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssuedCredentialsBySubjectDidForOverviewQuery, GetIssuedCredentialsBySubjectDidForOverviewQueryVariables>(GetIssuedCredentialsBySubjectDidForOverviewDocument, options);
      }
export function useGetIssuedCredentialsBySubjectDidForOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssuedCredentialsBySubjectDidForOverviewQuery, GetIssuedCredentialsBySubjectDidForOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssuedCredentialsBySubjectDidForOverviewQuery, GetIssuedCredentialsBySubjectDidForOverviewQueryVariables>(GetIssuedCredentialsBySubjectDidForOverviewDocument, options);
        }
export function useGetIssuedCredentialsBySubjectDidForOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIssuedCredentialsBySubjectDidForOverviewQuery, GetIssuedCredentialsBySubjectDidForOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIssuedCredentialsBySubjectDidForOverviewQuery, GetIssuedCredentialsBySubjectDidForOverviewQueryVariables>(GetIssuedCredentialsBySubjectDidForOverviewDocument, options);
        }
export type GetIssuedCredentialsBySubjectDidForOverviewQueryHookResult = ReturnType<typeof useGetIssuedCredentialsBySubjectDidForOverviewQuery>;
export type GetIssuedCredentialsBySubjectDidForOverviewLazyQueryHookResult = ReturnType<typeof useGetIssuedCredentialsBySubjectDidForOverviewLazyQuery>;
export type GetIssuedCredentialsBySubjectDidForOverviewSuspenseQueryHookResult = ReturnType<typeof useGetIssuedCredentialsBySubjectDidForOverviewSuspenseQuery>;
export type GetIssuedCredentialsBySubjectDidForOverviewQueryResult = Apollo.QueryResult<GetIssuedCredentialsBySubjectDidForOverviewQuery, GetIssuedCredentialsBySubjectDidForOverviewQueryVariables>;
export const GetSubjectDidsDocument = gql`
    query GetSubjectDids {
  getSubjectDids {
    id
    name
    zinlDid
  }
}
    `;

/**
 * __useGetSubjectDidsQuery__
 *
 * To run a query within a React component, call `useGetSubjectDidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectDidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectDidsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubjectDidsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubjectDidsQuery, GetSubjectDidsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectDidsQuery, GetSubjectDidsQueryVariables>(GetSubjectDidsDocument, options);
      }
export function useGetSubjectDidsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectDidsQuery, GetSubjectDidsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectDidsQuery, GetSubjectDidsQueryVariables>(GetSubjectDidsDocument, options);
        }
export function useGetSubjectDidsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSubjectDidsQuery, GetSubjectDidsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubjectDidsQuery, GetSubjectDidsQueryVariables>(GetSubjectDidsDocument, options);
        }
export type GetSubjectDidsQueryHookResult = ReturnType<typeof useGetSubjectDidsQuery>;
export type GetSubjectDidsLazyQueryHookResult = ReturnType<typeof useGetSubjectDidsLazyQuery>;
export type GetSubjectDidsSuspenseQueryHookResult = ReturnType<typeof useGetSubjectDidsSuspenseQuery>;
export type GetSubjectDidsQueryResult = Apollo.QueryResult<GetSubjectDidsQuery, GetSubjectDidsQueryVariables>;
export const GetExchangeProfilesDocument = gql`
    query getExchangeProfiles {
  getExchangeProfiles {
    id
    name
    url
    tagsAndBranches {
      tagOrBranch
    }
  }
}
    `;

/**
 * __useGetExchangeProfilesQuery__
 *
 * To run a query within a React component, call `useGetExchangeProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExchangeProfilesQuery(baseOptions?: Apollo.QueryHookOptions<GetExchangeProfilesQuery, GetExchangeProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExchangeProfilesQuery, GetExchangeProfilesQueryVariables>(GetExchangeProfilesDocument, options);
      }
export function useGetExchangeProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeProfilesQuery, GetExchangeProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExchangeProfilesQuery, GetExchangeProfilesQueryVariables>(GetExchangeProfilesDocument, options);
        }
export function useGetExchangeProfilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetExchangeProfilesQuery, GetExchangeProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetExchangeProfilesQuery, GetExchangeProfilesQueryVariables>(GetExchangeProfilesDocument, options);
        }
export type GetExchangeProfilesQueryHookResult = ReturnType<typeof useGetExchangeProfilesQuery>;
export type GetExchangeProfilesLazyQueryHookResult = ReturnType<typeof useGetExchangeProfilesLazyQuery>;
export type GetExchangeProfilesSuspenseQueryHookResult = ReturnType<typeof useGetExchangeProfilesSuspenseQuery>;
export type GetExchangeProfilesQueryResult = Apollo.QueryResult<GetExchangeProfilesQuery, GetExchangeProfilesQueryVariables>;
export const GetCredentialDetailsForIndicatorsDocument = gql`
    query GetCredentialDetailsForIndicators($repositoryUrl: String!, $branchOrTag: String, $indicators: [IndicatorInput!]!) {
  getCredentialDetailsForIndicators(
    input: {repositoryUrl: $repositoryUrl, branchOrTag: $branchOrTag}
    indicators: $indicators
  ) {
    indicator {
      name
      path
    }
    queryName
    queryDescription
    sparql
    profileUrl
    ontologies {
      title
      tagOrBranch
    }
    parameters {
      ... on ReferenceDateParameterDto {
        type
        path
        name
      }
      ... on QuarterParameterDto {
        type
        path
        name
        possibleValues
      }
      ... on YearParameterDto {
        type
        path
        name
        maxValue
        minValue
      }
    }
  }
}
    `;

/**
 * __useGetCredentialDetailsForIndicatorsQuery__
 *
 * To run a query within a React component, call `useGetCredentialDetailsForIndicatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCredentialDetailsForIndicatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCredentialDetailsForIndicatorsQuery({
 *   variables: {
 *      repositoryUrl: // value for 'repositoryUrl'
 *      branchOrTag: // value for 'branchOrTag'
 *      indicators: // value for 'indicators'
 *   },
 * });
 */
export function useGetCredentialDetailsForIndicatorsQuery(baseOptions: Apollo.QueryHookOptions<GetCredentialDetailsForIndicatorsQuery, GetCredentialDetailsForIndicatorsQueryVariables> & ({ variables: GetCredentialDetailsForIndicatorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCredentialDetailsForIndicatorsQuery, GetCredentialDetailsForIndicatorsQueryVariables>(GetCredentialDetailsForIndicatorsDocument, options);
      }
export function useGetCredentialDetailsForIndicatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCredentialDetailsForIndicatorsQuery, GetCredentialDetailsForIndicatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCredentialDetailsForIndicatorsQuery, GetCredentialDetailsForIndicatorsQueryVariables>(GetCredentialDetailsForIndicatorsDocument, options);
        }
export function useGetCredentialDetailsForIndicatorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCredentialDetailsForIndicatorsQuery, GetCredentialDetailsForIndicatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCredentialDetailsForIndicatorsQuery, GetCredentialDetailsForIndicatorsQueryVariables>(GetCredentialDetailsForIndicatorsDocument, options);
        }
export type GetCredentialDetailsForIndicatorsQueryHookResult = ReturnType<typeof useGetCredentialDetailsForIndicatorsQuery>;
export type GetCredentialDetailsForIndicatorsLazyQueryHookResult = ReturnType<typeof useGetCredentialDetailsForIndicatorsLazyQuery>;
export type GetCredentialDetailsForIndicatorsSuspenseQueryHookResult = ReturnType<typeof useGetCredentialDetailsForIndicatorsSuspenseQuery>;
export type GetCredentialDetailsForIndicatorsQueryResult = Apollo.QueryResult<GetCredentialDetailsForIndicatorsQuery, GetCredentialDetailsForIndicatorsQueryVariables>;
export const GetSubjectDidDocument = gql`
    query GetSubjectDid($id: Long!) {
  getSubjectDid(id: $id) {
    id
    name
    zinlDid
  }
}
    `;

/**
 * __useGetSubjectDidQuery__
 *
 * To run a query within a React component, call `useGetSubjectDidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectDidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectDidQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubjectDidQuery(baseOptions: Apollo.QueryHookOptions<GetSubjectDidQuery, GetSubjectDidQueryVariables> & ({ variables: GetSubjectDidQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectDidQuery, GetSubjectDidQueryVariables>(GetSubjectDidDocument, options);
      }
export function useGetSubjectDidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectDidQuery, GetSubjectDidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectDidQuery, GetSubjectDidQueryVariables>(GetSubjectDidDocument, options);
        }
export function useGetSubjectDidSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSubjectDidQuery, GetSubjectDidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubjectDidQuery, GetSubjectDidQueryVariables>(GetSubjectDidDocument, options);
        }
export type GetSubjectDidQueryHookResult = ReturnType<typeof useGetSubjectDidQuery>;
export type GetSubjectDidLazyQueryHookResult = ReturnType<typeof useGetSubjectDidLazyQuery>;
export type GetSubjectDidSuspenseQueryHookResult = ReturnType<typeof useGetSubjectDidSuspenseQuery>;
export type GetSubjectDidQueryResult = Apollo.QueryResult<GetSubjectDidQuery, GetSubjectDidQueryVariables>;
export const IssueCredentialsForHealthcareProvidersDocument = gql`
    mutation IssueCredentialsForHealthcareProviders($ids: [Long!]!) {
  issueCredentialsForHealthcareProviders(ids: $ids) {
    id
    successful
    unsuccessful {
      reasonPhrase
      location
    }
  }
}
    `;
export type IssueCredentialsForHealthcareProvidersMutationFn = Apollo.MutationFunction<IssueCredentialsForHealthcareProvidersMutation, IssueCredentialsForHealthcareProvidersMutationVariables>;

/**
 * __useIssueCredentialsForHealthcareProvidersMutation__
 *
 * To run a mutation, you first call `useIssueCredentialsForHealthcareProvidersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueCredentialsForHealthcareProvidersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueCredentialsForHealthcareProvidersMutation, { data, loading, error }] = useIssueCredentialsForHealthcareProvidersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useIssueCredentialsForHealthcareProvidersMutation(baseOptions?: Apollo.MutationHookOptions<IssueCredentialsForHealthcareProvidersMutation, IssueCredentialsForHealthcareProvidersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueCredentialsForHealthcareProvidersMutation, IssueCredentialsForHealthcareProvidersMutationVariables>(IssueCredentialsForHealthcareProvidersDocument, options);
      }
export type IssueCredentialsForHealthcareProvidersMutationHookResult = ReturnType<typeof useIssueCredentialsForHealthcareProvidersMutation>;
export type IssueCredentialsForHealthcareProvidersMutationResult = Apollo.MutationResult<IssueCredentialsForHealthcareProvidersMutation>;
export type IssueCredentialsForHealthcareProvidersMutationOptions = Apollo.BaseMutationOptions<IssueCredentialsForHealthcareProvidersMutation, IssueCredentialsForHealthcareProvidersMutationVariables>;
export const RevokeHealthcareProviderExcerptCredentialDocument = gql`
    mutation RevokeHealthcareProviderExcerptCredential($ids: [UUID!]!) {
  revokeHealthcareProviderExcerptCredential(ids: $ids) {
    id
    successful
    unsuccessful {
      reasonPhrase
      location
    }
  }
}
    `;
export type RevokeHealthcareProviderExcerptCredentialMutationFn = Apollo.MutationFunction<RevokeHealthcareProviderExcerptCredentialMutation, RevokeHealthcareProviderExcerptCredentialMutationVariables>;

/**
 * __useRevokeHealthcareProviderExcerptCredentialMutation__
 *
 * To run a mutation, you first call `useRevokeHealthcareProviderExcerptCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeHealthcareProviderExcerptCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeHealthcareProviderExcerptCredentialMutation, { data, loading, error }] = useRevokeHealthcareProviderExcerptCredentialMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRevokeHealthcareProviderExcerptCredentialMutation(baseOptions?: Apollo.MutationHookOptions<RevokeHealthcareProviderExcerptCredentialMutation, RevokeHealthcareProviderExcerptCredentialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeHealthcareProviderExcerptCredentialMutation, RevokeHealthcareProviderExcerptCredentialMutationVariables>(RevokeHealthcareProviderExcerptCredentialDocument, options);
      }
export type RevokeHealthcareProviderExcerptCredentialMutationHookResult = ReturnType<typeof useRevokeHealthcareProviderExcerptCredentialMutation>;
export type RevokeHealthcareProviderExcerptCredentialMutationResult = Apollo.MutationResult<RevokeHealthcareProviderExcerptCredentialMutation>;
export type RevokeHealthcareProviderExcerptCredentialMutationOptions = Apollo.BaseMutationOptions<RevokeHealthcareProviderExcerptCredentialMutation, RevokeHealthcareProviderExcerptCredentialMutationVariables>;
export const IssueCredentialsForIndicatorsDocument = gql`
    mutation IssueCredentialsForIndicators($repositoryUrl: String!, $branchOrTag: String, $indicators: [IndicatorInput!]!, $subjectIds: [Long!]!) {
  issueCredentialsForIndicators(
    input: {repositoryUrl: $repositoryUrl, branchOrTag: $branchOrTag}
    indicators: $indicators
    subjectIds: $subjectIds
  ) {
    indicator {
      name
      path
    }
    successful
    unsuccessful {
      reasonPhrase
      location
    }
  }
}
    `;
export type IssueCredentialsForIndicatorsMutationFn = Apollo.MutationFunction<IssueCredentialsForIndicatorsMutation, IssueCredentialsForIndicatorsMutationVariables>;

/**
 * __useIssueCredentialsForIndicatorsMutation__
 *
 * To run a mutation, you first call `useIssueCredentialsForIndicatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueCredentialsForIndicatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueCredentialsForIndicatorsMutation, { data, loading, error }] = useIssueCredentialsForIndicatorsMutation({
 *   variables: {
 *      repositoryUrl: // value for 'repositoryUrl'
 *      branchOrTag: // value for 'branchOrTag'
 *      indicators: // value for 'indicators'
 *      subjectIds: // value for 'subjectIds'
 *   },
 * });
 */
export function useIssueCredentialsForIndicatorsMutation(baseOptions?: Apollo.MutationHookOptions<IssueCredentialsForIndicatorsMutation, IssueCredentialsForIndicatorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueCredentialsForIndicatorsMutation, IssueCredentialsForIndicatorsMutationVariables>(IssueCredentialsForIndicatorsDocument, options);
      }
export type IssueCredentialsForIndicatorsMutationHookResult = ReturnType<typeof useIssueCredentialsForIndicatorsMutation>;
export type IssueCredentialsForIndicatorsMutationResult = Apollo.MutationResult<IssueCredentialsForIndicatorsMutation>;
export type IssueCredentialsForIndicatorsMutationOptions = Apollo.BaseMutationOptions<IssueCredentialsForIndicatorsMutation, IssueCredentialsForIndicatorsMutationVariables>;
export const RevokeCredentialsDocument = gql`
    mutation revokeCredentials($ids: [UUID!]!) {
  revokeCredentials(ids: $ids) {
    id
    successful
    unsuccessful {
      reasonPhrase
      location
    }
  }
}
    `;
export type RevokeCredentialsMutationFn = Apollo.MutationFunction<RevokeCredentialsMutation, RevokeCredentialsMutationVariables>;

/**
 * __useRevokeCredentialsMutation__
 *
 * To run a mutation, you first call `useRevokeCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeCredentialsMutation, { data, loading, error }] = useRevokeCredentialsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRevokeCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<RevokeCredentialsMutation, RevokeCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeCredentialsMutation, RevokeCredentialsMutationVariables>(RevokeCredentialsDocument, options);
      }
export type RevokeCredentialsMutationHookResult = ReturnType<typeof useRevokeCredentialsMutation>;
export type RevokeCredentialsMutationResult = Apollo.MutationResult<RevokeCredentialsMutation>;
export type RevokeCredentialsMutationOptions = Apollo.BaseMutationOptions<RevokeCredentialsMutation, RevokeCredentialsMutationVariables>;
export const CreateSubjectDidDocument = gql`
    mutation CreateSubjectDid($input: CreateSubjectDidInput!) {
  createSubjectDid(input: $input) {
    name
    zinlDid
    id
  }
}
    `;
export type CreateSubjectDidMutationFn = Apollo.MutationFunction<CreateSubjectDidMutation, CreateSubjectDidMutationVariables>;

/**
 * __useCreateSubjectDidMutation__
 *
 * To run a mutation, you first call `useCreateSubjectDidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubjectDidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubjectDidMutation, { data, loading, error }] = useCreateSubjectDidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubjectDidMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubjectDidMutation, CreateSubjectDidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubjectDidMutation, CreateSubjectDidMutationVariables>(CreateSubjectDidDocument, options);
      }
export type CreateSubjectDidMutationHookResult = ReturnType<typeof useCreateSubjectDidMutation>;
export type CreateSubjectDidMutationResult = Apollo.MutationResult<CreateSubjectDidMutation>;
export type CreateSubjectDidMutationOptions = Apollo.BaseMutationOptions<CreateSubjectDidMutation, CreateSubjectDidMutationVariables>;
export const RevokeSubjectDidDocument = gql`
    mutation RevokeSubjectDid($id: Long!) {
  revokeSubjectDid(id: $id) {
    success
  }
}
    `;
export type RevokeSubjectDidMutationFn = Apollo.MutationFunction<RevokeSubjectDidMutation, RevokeSubjectDidMutationVariables>;

/**
 * __useRevokeSubjectDidMutation__
 *
 * To run a mutation, you first call `useRevokeSubjectDidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeSubjectDidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeSubjectDidMutation, { data, loading, error }] = useRevokeSubjectDidMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeSubjectDidMutation(baseOptions?: Apollo.MutationHookOptions<RevokeSubjectDidMutation, RevokeSubjectDidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeSubjectDidMutation, RevokeSubjectDidMutationVariables>(RevokeSubjectDidDocument, options);
      }
export type RevokeSubjectDidMutationHookResult = ReturnType<typeof useRevokeSubjectDidMutation>;
export type RevokeSubjectDidMutationResult = Apollo.MutationResult<RevokeSubjectDidMutation>;
export type RevokeSubjectDidMutationOptions = Apollo.BaseMutationOptions<RevokeSubjectDidMutation, RevokeSubjectDidMutationVariables>;
export const UpdateSubjectDidDocument = gql`
    mutation UpdateSubjectDid($input: UpdateSubjectDidInput!) {
  updateSubjectDid(input: $input) {
    name
    zinlDid
  }
}
    `;
export type UpdateSubjectDidMutationFn = Apollo.MutationFunction<UpdateSubjectDidMutation, UpdateSubjectDidMutationVariables>;

/**
 * __useUpdateSubjectDidMutation__
 *
 * To run a mutation, you first call `useUpdateSubjectDidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubjectDidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubjectDidMutation, { data, loading, error }] = useUpdateSubjectDidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubjectDidMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubjectDidMutation, UpdateSubjectDidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubjectDidMutation, UpdateSubjectDidMutationVariables>(UpdateSubjectDidDocument, options);
      }
export type UpdateSubjectDidMutationHookResult = ReturnType<typeof useUpdateSubjectDidMutation>;
export type UpdateSubjectDidMutationResult = Apollo.MutationResult<UpdateSubjectDidMutation>;
export type UpdateSubjectDidMutationOptions = Apollo.BaseMutationOptions<UpdateSubjectDidMutation, UpdateSubjectDidMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    firstName
    lastName
    id
    email
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeactivateUserDocument = gql`
    mutation DeactivateUser($id: String!) {
  deactivateUser(id: $id) {
    firstName
    id
    isEnabled
  }
}
    `;
export type DeactivateUserMutationFn = Apollo.MutationFunction<DeactivateUserMutation, DeactivateUserMutationVariables>;

/**
 * __useDeactivateUserMutation__
 *
 * To run a mutation, you first call `useDeactivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserMutation, { data, loading, error }] = useDeactivateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateUserMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateUserMutation, DeactivateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateUserMutation, DeactivateUserMutationVariables>(DeactivateUserDocument, options);
      }
export type DeactivateUserMutationHookResult = ReturnType<typeof useDeactivateUserMutation>;
export type DeactivateUserMutationResult = Apollo.MutationResult<DeactivateUserMutation>;
export type DeactivateUserMutationOptions = Apollo.BaseMutationOptions<DeactivateUserMutation, DeactivateUserMutationVariables>;
export const PingDocument = gql`
    query Ping {
  ping
}
    `;

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingQuery(baseOptions?: Apollo.QueryHookOptions<PingQuery, PingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PingQuery, PingQueryVariables>(PingDocument, options);
      }
export function usePingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PingQuery, PingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PingQuery, PingQueryVariables>(PingDocument, options);
        }
export function usePingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PingQuery, PingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PingQuery, PingQueryVariables>(PingDocument, options);
        }
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export type PingSuspenseQueryHookResult = ReturnType<typeof usePingSuspenseQuery>;
export type PingQueryResult = Apollo.QueryResult<PingQuery, PingQueryVariables>;