import type { CalendarProps, DateObject, Value } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Box from "@mui/material/Box";
import { StyledCalendar } from "./MultiDatePicker.styles";
import nl from "./nl";

export interface MultiDatePickerProps
    extends Pick<CalendarProps, "multiple" | "locale"> {
    dates?: Value | string[] | null;
    onChange?: (
        selectedDates: DateObject | DateObject[] | DateObject[][] | null
    ) => false | void;
}

function MultiDatePicker({
    dates,
    onChange,
    locale = nl,
}: MultiDatePickerProps) {
    return (
        <Box
            sx={(theme) => ({
                ".rmdp-wrapper": {
                    borderRadius: 0,
                },
                ".rmdp-shadow": {
                    boxShadow: theme.shadows[3],
                    border: `1px solid ${theme.palette.divider}`,
                },
            })}
        >
            <StyledCalendar
                format="DD-MM-YYYY"
                headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                monthYearSeparator="&nbsp;"
                plugins={[<DatePanel key="datePanel" />]}
                value={dates}
                onChange={onChange}
                locale={locale}
                multiple={true}
            />
        </Box>
    );
}

export default MultiDatePicker;
