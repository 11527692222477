import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    fromMultiSelectOption,
    MultiSelect,
    toMultiSelectOption,
} from "@/src/components/organisms/MultiSelect";
import type { SelectChangeEvent } from "@mui/material/Select";

type Quarter = string;
type Quarters = Quarter[];

export interface YearParameterComponentProps {
    options: Quarters;
    value?: Quarters;
    onChange?: (quarters: Quarters) => void;
    label?: string;
}

export function QuarterParameter(props: YearParameterComponentProps) {
    const { t } = useTranslation("common"),
        label = props.label ?? t("year", "Kwartaal");

    const quarters = useMemo(
        () => toMultiSelectOption(props.options ?? []),
        [props.options]
    );

    const value = useMemo(
        () => toMultiSelectOption(props.value ?? []),
        [props.value]
    );

    function handleChange(
        event: SelectChangeEvent<{ label: string; value: string }[]>
    ) {
        if (Array.isArray(event.target.value))
            props.onChange?.(fromMultiSelectOption(event.target.value));
    }

    return (
        <MultiSelect
            label={label}
            options={quarters}
            value={value}
            onChange={handleChange}
        />
    );
}
