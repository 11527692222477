import { parse, format, isValid } from "date-fns";
import type { DateObject } from "react-multi-date-picker";

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace multiDateFormat {
    type DateOrString = Date | string;
    type Output = DateObject | DateObject[] | DateObject[][] | null;
}

function multiDateFormat(dates: multiDateFormat.DateOrString[] = []) {
    return dates.map(parseInput).map(formatOutput);
}

multiDateFormat.input = multiDateFormat;

multiDateFormat.output = function multiDateFormatOutput(
    dates: multiDateFormat.Output = []
) {
    if (!Array.isArray(dates)) {
        throw new Error("Expected an array of dates");
    }

    return dates
        .toString()
        .split(",")
        .map((d) => {
            const parsedDate = parse(d, "dd-MM-yyyy", new Date());
            return isValid(parsedDate)
                ? format(parsedDate, "yyyy-MM-dd")
                : null;
        })
        .filter((d) => d !== null);
};

function parseInput(date: Date | string) {
    return parse(date.toString(), "yyyy-MM-dd", new Date());
}

function formatOutput(date: Date) {
    return format(date, "dd-MM-yyyy");
}

export default multiDateFormat;
