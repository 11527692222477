import { useId } from "react";
import type { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import type { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import type { MultiSelectOption } from "./MultiSelectOption";

export interface MultiSelectComponentProps<T = MultiSelectOption> {
    options: T[];
    value?: T[];
    onChange?: (event: SelectChangeEvent<T[]>) => void;
    label?: string;
    id?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(
    option: MultiSelectOption,
    value: readonly MultiSelectOption[],
    theme: Theme
) {
    return {
        fontWeight:
            value.indexOf(option) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export function MultiSelect(props: MultiSelectComponentProps) {
    const { options = [], value = [], onChange } = props;

    const theme = useTheme();

    const id = props.id ?? useId(),
        labelId = `${id}-label`,
        selectId = `${id}-select`,
        inputId = `${id}-input`;

    const label = props.label;

    function handleChange(event: SelectChangeEvent<string>) {
        const newValue = event.target.value;
        const newEvent = Object.assign({}, event, {
            target: {
                value: options.filter((option) =>
                    newValue.includes(option.value)
                ),
            },
        });
        onChange?.(newEvent);
    }

    return (
        <FormControl sx={{ width: 300 }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                id={selectId}
                multiple
                // @ts-expect-error value is not a string
                value={value.map((v: any) => v.value)}
                onChange={handleChange}
                input={<OutlinedInput id={inputId} label={label} />}
                renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {(selected as unknown as string[]).map((v) => (
                            <Chip
                                key={v}
                                label={
                                    options.find((option) => option.value === v)
                                        ?.label
                                }
                            />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        style={getStyles(option, value, theme)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
