import { type ComponentType, Fragment, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { cloneDeep, set } from "lodash";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import MultiDatePicker from "@/src/components/organisms/MultiDatePicker/MultiDatePicker";
import { multiDateFormat } from "@/src/lib/multiDateFormat";
import { createLogger } from "@/src/lib/logger";
import { YearParameter } from "@/src/components/templates/CreateValidatedQuestion/steps/Parameters/Parameters/YearParameter";
import { QuarterParameter } from "@/src/components/templates/CreateValidatedQuestion/steps/Parameters/Parameters/QuarterParameter";
import { notUndefinedOrNull } from "@/src/lib/notNullOrUndefined/notNullOrUndefined";

const logger = createLogger("Parameters");

export interface ParametersProps {
    indicatorDetails: {
        queryName: string;
        parameters: Parameter[];
    };
}

interface BaseParameter {
    name: string;
    path: string;
}

interface ReferenceDateParameter extends BaseParameter {
    type: "REFERENCE_DATE";
    possibleValues: string[] | Date[];
}

interface YearParameter extends BaseParameter {
    type: "YEAR";
    minValue: string;
    maxValue: string;
    possibleValues: string[];
}

interface QuarterParameter extends BaseParameter {
    type: "QUARTER";
    possibleValues: string[];
}

export type Parameter =
    | ReferenceDateParameter
    | YearParameter
    | QuarterParameter;

export interface Indicator {
    name: string;
    parameters: Parameter[];
}

function Parameters({ indicatorDetails }: ParametersProps) {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();

    const selectedIndicators: Indicator[] = useWatch({
        control,
        name: "indicators",
    });

    const indexOfCurrent = useMemo(() => {
        return selectedIndicators.findIndex(
            (selectedIndicator) =>
                selectedIndicator.name === indicatorDetails.queryName
        );
    }, [selectedIndicators, indicatorDetails.queryName]);

    const currentValue = selectedIndicators[indexOfCurrent];

    function setPossibleValues(index: number, data: any) {
        setValue(
            `indicators.${indexOfCurrent}`,
            set(
                cloneDeep(currentValue),
                `parameters[${index}].possibleValues`,
                data
            )
        );
    }

    const components = indicatorDetails.parameters.map(
        (indicatorDetailParameter: Parameter, index: number) => {
            switch (indicatorDetailParameter.type) {
                case "REFERENCE_DATE":
                    return withTitle(
                        t("parameters.reference-date"),
                        MultiDatePicker,
                        {
                            locale: undefined,
                            multiple: undefined,
                            dates: multiDateFormat.input(
                                currentValue?.parameters?.[index]
                                    ?.possibleValues
                            ),
                            onChange: (dates) => {
                                setPossibleValues(
                                    index,
                                    multiDateFormat.output(dates)
                                );
                            },
                        }
                    );
                case "YEAR":
                    return withTitle(
                        t("parameters.year", "Jaartal"),
                        YearParameter,
                        {
                            minInclusive: indicatorDetailParameter.minValue,
                            maxInclusive: indicatorDetailParameter.maxValue,
                            // @ts-expect-error type needs to be narrowed down
                            value: findByPath(
                                currentValue.parameters,
                                indicatorDetailParameter.path
                            )?.possibleValues,
                            onChange: (years) => {
                                setPossibleValues(index, years);
                            },
                        }
                    );
                case "QUARTER":
                    return withTitle(
                        t("parameters.quarter", "Kwartaal"),
                        QuarterParameter,
                        {
                            options: indicatorDetailParameter.possibleValues,
                            // @ts-expect-error type needs to be narrowed down
                            value: findByPath(
                                currentValue.parameters,
                                indicatorDetailParameter.path
                            )?.possibleValues,
                            onChange: (quarters) => {
                                setPossibleValues(index, quarters);
                            },
                        }
                    );
                default:
                    logger.warn(
                        `Unknown parameter type: ${(indicatorDetailParameter as any)?.type}`
                    );
                    return null;
            }
        }
    );

    return (
        <Box display="flex" flexDirection="column" gap={1}>
            {components.filter(notUndefinedOrNull).map((component, index) => {
                return <Fragment key={index}>{component}</Fragment>;
            })}
        </Box>
    );
}

function withTitle<P extends object>(
    title: string,
    Component: ComponentType<P>,
    props: P
) {
    return (
        <Box data-test-id="parameter">
            <Typography variant="overline">{title}</Typography>
            <br />
            <Component {...props} />
        </Box>
    );
}

export default Parameters;

function findByPath<T extends { path: string }>(
    parameters: T[],
    path: string
): T | undefined {
    if (!parameters) {
        return undefined;
    }

    let _parameters: typeof parameters;

    if (Array.isArray(parameters)) {
        _parameters = parameters;
    } else {
        _parameters = Object.values(parameters);
    }

    return _parameters.find((parameter) => parameter.path === path);
}
