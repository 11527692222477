import { styled, keyframes } from "@mui/system";
import { Calendar } from "react-multi-date-picker";
import type { Theme } from "@mui/material/styles";

const DayFade = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
    transformOrigin: center;
  }
  75% {
    opacity: 0.2;
    transform: scale(0.75);
  }
  100% {
    opacity: 0;
  }
`;

export const StyledCalendar = styled(Calendar)(
    ({ theme }: { theme?: Theme }) => ({
        ".rmdp-day": {
            width: "36px",
            height: "36px",
        },
        ".rmdp-header div[style*='relative']": {
            justifyContent: "flexEnd",
        },
        ".rmdp-header": {
            paddingTop: "5px",
            marginTop: 0,
        },
        ".rmdp-header-values": {
            marginLeft: 0,
            padding: "0px 10px",

            span: {
                padding: 0,
            },
        },
        ".rmdp-day span": {
            color: theme?.palette.text.primary,
            fontSize: theme?.typography.caption.fontSize,
            transition: `background ${theme?.transitions.duration.shortest}ms ${theme?.transitions.easing.easeIn}`,
        },
        ".rmdp-day.rmdp-selected:after": {
            transform: "scale(1)",
            animation: `${DayFade} ${theme?.transitions.duration.standard}ms ${theme?.transitions.easing.easeIn} forwards`,
        },
        ".rmdp-day:after": {
            content: "''",
            display: "inline-block",
            position: "absolute",
            top: "2px",
            left: "2px",
            width: "calc(100% - 4px)",
            height: "calc(100% - 4px)",
            borderRadius: "50%",
            backgroundColor: theme?.palette.common.white,
            transform: "scale(0)",
            opacity: 0,
        },
        ".rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover ": {
            backgroundColor: theme?.palette.primary.light,
            color: theme?.palette.text.primary,
        },
        ".rmdp-day.rmdp-selected span:not(.highlight)": {
            backgroundColor: theme?.palette.primary.main,
            borderColor: theme?.palette.primary.main,
            color: theme?.palette.primary.contrastText,
            boxShadow: "none",
        },
        ".rmdp-day.rmdp-today span": {
            backgroundColor: "transparent",
            border: `1px solid ${theme?.palette.text.secondary}`,
        },
        ".rmdp-week .rmdp-week-day": {
            color: theme?.palette.text.secondary,
        },
        ".rmdp-arrow": {
            borderColor: `${theme?.palette.text.primary}`,
            marginTop: "6px",
        },
        ".rmdp-arrow-container": {
            width: "20px",
            height: "20px",
            position: "relative",
        },
        ".rmdp-arrow-container:hover": {
            backgroundColor: `${theme?.palette.action.hover}`,
            boxShadow: "none",

            ".rmdp-arrow": {
                borderColor: `${theme?.palette.action.active}`,
            },
        },
        ".rmdp-panel": {
            minWidth: "155px",
        },
        ".rmdp-panel-body": {
            padding: "10px",
        },
        ".rmdp-panel-body li, .rmdp-panel-body button": {
            backgroundColor: theme?.palette.primary.main,
            boxShadow: "none",
        },
        ".rmdp-panel-body li": {
            borderRadius: theme?.shape.borderRadius,
            marginBottom: theme?.spacing(0.5),
        },
        ".rmdp-panel-header": {
            height: 0,
            margin: 0,
            padding: 0,
        },
    })
);
