import type { Logger } from "./Logger";

export function createLogger(...args: any[]): Logger {
    return {
        log: console.log.bind(console, ...loggerize("LOG", ...args)),
        debug: console.debug.bind(console, ...loggerize("DEBUG", ...args)),
        verbose: console.debug.bind(console, ...loggerize("VERBOSE", ...args)),
        error: console.error.bind(console, ...loggerize("ERROR", ...args)),
        warn: console.warn.bind(console, ...loggerize("WARN", ...args)),
        info: console.info.bind(console, ...loggerize("INFO", ...args)),
    };
}

function bracketize(value: unknown) {
    if (typeof value !== "string") return value;
    return `[${value}]`;
}

function loggerize(level: string, ...args: any[]) {
    return [level, ...args].map(bracketize);
}
